<template>
    <div :class="`m-3 p-2 wrapper wrapper--${bu}`">
        <h2 class="text-center">Langstaand verkocht</h2>
        <hr>
        <Loading v-if="loading" />
        <table v-else :class="`w-100 table--default table__border--${bu}`">
            <thead :class="`table__head--${bu}`">
                <tr>
                    <th class="table__head--text">Itemnummer</th>
                    <th class="table__head--text">Soort</th>
                    <th class="table__data--right table__head--text">Opbouw</th>
                    <th class="table__data--right table__head--text">Merk</th>
                    <th class="table__data--right table__head--text">Verkocht op</th>
                    <th class="table__data--right table__head--text">Dagen V</th>
                    <th class="table__data--right table__head--text">Verkoper</th>
                    <th class="table__data--right table__head--text">Prijs</th>
                    <th class="table__data--right table__head--text">Betaald</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, key) in long_standing_sold_vehicles" :key="key" :class="`table__row--color`">
                    <td class="table__cell--default text-center">
                        <ImageHover :itemnummer='item.itemno' :bu='bu' />
                    </td>
                    <td class="table__cell--default table__cell--right">{{ item.soortnaam }}</td>
                    <td class="table__cell--default table__cell--right">{{ item.opbouwnaam }}</td>
                    <td class="table__cell--default table__cell--right">{{ item.merktype }}</td>
                    <td class="table__cell--default text-center">{{ item.datum }}</td>
                    <td class="table__cell--default text-center">{{ item.dagenverkocht }}</td>
                    <td class="table__cell--default table__cell--right">{{ item.verkopernaam }}</td>
                    <td class="table__cell--default table__cell--right">€ {{ item.prijs }}</td>
                    <td class="table__cell--default table__cell--right">€ {{ item.Betaald }}</td>
                </tr>
            </tbody>
        </table>
        <span class=""><strong>Een voertuig geldt als langstaand na 90 dagen</strong></span>
    </div>
</template>
<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";

export default {
    props: ["bu"],
    components: { Loading, ImageHover },
    data: () => ({
        loading: true,
        long_standing_sold_vehicles: null,
    }),
    created() {
        this.getData(this.bu);
    },
    watch: {
        bu(newbu) {
            this.loading = true;
            this.getData(newbu);
        }
    },
    methods: {
        getData(bu) {
            request(`long-stock-sold-vehicles/${bu}`, "GET").then(
                ({ long_standing_sold_vehicles }) => {
                    this.long_standing_sold_vehicles = long_standing_sold_vehicles;
                    this.loading = false;
                }
            );
        },
    },
};
</script>
  